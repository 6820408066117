import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="relative">
        <div className="absolute z-1 w-full space-y-8">
          <h1 className="text-blue-500 text-8xl font-extralight">
            Riti Salay
          </h1>
          <p className="text-babypurple text-3xl">
            I know it annoys the fuck out of you, hehe 😈
          </p>
          {/* <h1 className="text-blue-500 text-9xl font-extralight m-auto">
            <a href="http://salay.eshansingh.net" target="_blank" rel="noopener noreferrer">SALAY.ESHANSINGH.NET</a>
          </h1> */}
        </div>
        <video
          autoPlay
          muted
          loop
          className="object-contain w-full h-screen m-auto z-0 relative opacity-70"
        >
          <source
            src="https://eshansingh.s3.ap-south-1.amazonaws.com/salay.mp4"
            type="video/mp4"
          />
        </video>
        <footer>
          <div className="text-babypurple text-xy font-extralight">
            <p>Made with <span>💙</span> and intent to annoy -by your weirdo</p>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;
